import {CartProduct, CartProductOptionPrice, OrderInformation, ProductOption} from "../../types";
import {getCartItemPriceDetail, getCartTotal, getPriceDiscount} from "../../utils";

const convertCartItemsToProductConfig = (property: any, price:number|undefined,  discount = 0, cartItemProductOptionPrices?: Array<CartProductOptionPrice>) => {
  if (!property) return null;
  const calculatedProductConfigPrice =  cartItemProductOptionPrices && cartItemProductOptionPrices.find(obj => obj.id === property.id)?.price;
  const productConfigPrice = typeof calculatedProductConfigPrice === 'number' ? calculatedProductConfigPrice : price;
  const priceDiscount = getPriceDiscount(productConfigPrice, discount);
  return {
    id: property.id,
    data: {
      type: property.data?.type,
      name: property.data?.name,
      sku: property.data?.sku,
      color: property.data?.web_format || property.data?.color_option,
      price: typeof productConfigPrice === 'number' ? productConfigPrice - priceDiscount : productConfigPrice,
      rrp: productConfigPrice,
      logoOption: property.data?.logo_option,
    },
  };
};

// map cart items to order object for API, remove unused properties
export const mapCartToOrder = (
  cartItems: CartProduct[],
  projectId?: string,
  orderInformation?: OrderInformation,
  userId?: string,
  discount?: number,
  settings?: any,
) => {
  const cartTotal = getCartTotal(cartItems, settings, discount);
  return {
    project: projectId,
    userId: userId,
    cart: cartItems.map((cartItem: CartProduct) => {
      const cartItemDetail = getCartItemPriceDetail(cartItem);

      return {
        id: cartItem.id,
        productModelPicture: cartItem.productModelPicture,
        productGroup: convertCartItemsToProductConfig(
          cartItem.productGroup,
          cartItemDetail.productGroupPrice,
          discount,
        ),
        product: convertCartItemsToProductConfig(cartItem.product, cartItemDetail.productPrice, discount),
        mainFabric: convertCartItemsToProductConfig(
          cartItem.fabric?.main,
          cartItemDetail.fabricMainPrice,
          discount,
        ),
        contrastFabric: convertCartItemsToProductConfig(
          cartItem.fabric?.contrast,
          cartItemDetail.fabricContrastPrice,
          discount,
        ),
        sizing: cartItem.amount,
        productOptions: cartItem.productOptions?.map(
          (option: ProductOption) => {
            const productOptionPrice = cartItemDetail.productOptionPrices?.find(curr => curr.id === option.id);
            const logoPrice = cartItemDetail.productOptionPrices?.find(curr => curr.id === option.id)?.price;
            const price = productOptionPrice?.logo_option === true ? logoPrice : productOptionPrice?.price;
            const productConfig = convertCartItemsToProductConfig(option, price, discount);
            if (productConfig) {
              if (!option.data.fabrics && productConfig.data.color) {
                delete productConfig.data.color;
              }
              if (option.data.fabrics && typeof productConfig.data.color === 'undefined') {
                 productConfig.data.color = 'main';
              }
              if (typeof productConfig.data.logoOption === 'undefined') {
                delete productConfig.data.logoOption;
              }
            }
            return productConfig;
          },
        ),
        projectLogos: cartItem.projectLogos,
      };
    }),
    totalPrice: cartTotal.totalPrice.toFixed(2),
    totalRRP: getCartTotal(cartItems, settings, 0).totalPrice.toFixed(2),
    distributor: orderInformation?.distributor?.id,
    orderInformation: { ...orderInformation },
    userDiscount: discount,
    subtotal: cartTotal.subtotal,
    taxes: cartTotal.taxes,
    shippingCost: cartTotal.shippingCost,
  };
};

