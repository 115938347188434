import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { TranslationManager } from "./TranslationManager";
import { PrismicStaticDataManager } from "./PrismicStaticDataManager";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <PrismicStaticDataManager>
//           <TranslationManager>
//             <RouterProvider router={router} />
//           </TranslationManager>
//         </PrismicStaticDataManager>
//       </PersistGate>
//     </Provider>
//   </React.StrictMode>,
// );

root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PrismicStaticDataManager>
          <TranslationManager>
            {/** <CartManager > */}
              <RouterProvider router={router} />
            {/** </CartManager> */}
          </TranslationManager>
        </PrismicStaticDataManager>
      </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
