import { FC } from "react";
import { CartProduct } from "../../types";
import {
  getCartItemSizesPrice,
} from "../../utils";

interface CartItemPriceProps {
  cartItem: CartProduct;
}

export const CartItemSizesPrice: FC<CartItemPriceProps> = ({ cartItem }) => {

  return (
    <>
      {getCartItemSizesPrice(
        cartItem,
      ).toFixed(2) + "€"}
    </>
  );
};
