import { useEffect } from "react";
import { UserManager } from "oidc-client";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { selectUser, setToken } from "../../store/reducers/userReducer";
import {
  createIsCartValidSelector,
  selectCart,
} from "../../store/reducers/cartReducer";
import Layout from "../Layout";
import Title from "../ui/Title";
import Login from "../Login";
import CartItem from "./CartItem";
import { Button } from "../ui/Button";
import OrderSummary from "../Checkout/OrderSummary";
import PrismicTranslation from "../PrismicTranslation";
import { useGetDocumentByTypeQuery } from "../../api/prismic";
import { ContinueShopping } from "./ContinueShopping";
import { setActiveTab } from "../../store/reducers/uiReducer";
import { useSelector } from "react-redux";

const Cart = ({ userManager }: { userManager: UserManager }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const user = useAppSelector(selectUser);
  const cart = useAppSelector(selectCart);
  
  const dispatch = useAppDispatch();
  const isLoggedIn = !!user;

  const { data: settings } = useGetDocumentByTypeQuery({
    type: "settings",
    lang: "en-us",
  });

  // Create a selector with the settings parameter
  const isCartValidSelector = createIsCartValidSelector(settings);
  const validCart = useSelector(isCartValidSelector);

  useEffect(() => {
    !user &&
      userManager.getUser().then((currentUser) => {
        dispatch(setToken(currentUser?.access_token));
      });
  }, [dispatch, user, userManager]);

  useEffect(() => {
    if (cart.products.length === 0) {
      navigate(`/${projectId}`);
    }
    // Fix display of a second product mesh when switching between Cart and Configurator views when the Logo tab is open
    dispatch(setActiveTab(""));
  }, [cart, navigate, projectId, dispatch]);

  return (
    <Layout userManager={userManager} background>
      {isLoggedIn ? (
        <div className="flex flex-wrap flex-1 h-full">
          <div className="bg-gray flex flex-col w-full lg:w-2/3 pt-4 lg:px-10">
            <ContinueShopping />
            <Title size={1} label="cart" />
            {cart.products?.map((cartItem) => (
              <CartItem
                key={cartItem.id}
                cartItem={cartItem}
              />
            ))}
          </div>
          <div className="flex flex-col p-8 justify-between w-full md:w-1/3 bg-white max-h-[calc(100vh-88px)]">
            <OrderSummary
              showTitle
              showWarning
            />
            <div className="pr-4 pt-4 bg-white">
              <Button
                full
                disabled={!validCart}
                onClick={() => navigate(`/delivery/${projectId}`)}
                label="proceed_delivery"
              />
              <p className="text-text font-medium text-sm mt-4">
                <PrismicTranslation field="order_summary_warning_2" simple />
              </p>
            </div>
          </div>
        </div>
      ) : (
        <Login userManager={userManager} />
      )}
    </Layout>
  );
};

export default Cart;
