import { ChangeEvent, FC, KeyboardEvent, MouseEventHandler } from "react";
import PrismicTranslation from "../PrismicTranslation";

interface InputProps {
  type?: "text" | "number" | "email" | "password" | "checkbox" | "tel" | "search";
  label?: string;
  value?: string | number;
  defaultValue?: string | number;
  placeholder?: string;
  alternateLabel?: boolean;
  readOnly?: boolean;
  required?: boolean;
  pattern?: string;
  isError?: boolean;
  errorLabel?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  name?: string;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
}

export const Input: FC<InputProps> = ({
  label,
  placeholder,
  alternateLabel,
  onChange,
  type,
  value,
  readOnly,
  pattern,
  required,
  isError,
  errorLabel,
  onClick,
  disabled = false,
  onKeyDown,
  className,
  labelClassName,
  name,
}) => (
  <div className="mb-2 w-full" onClick={onClick}>
    {label && (
      <label
        htmlFor={name || label}
        className={`block font-bold leading-6 ${alternateLabel ? "text-black text-sm" : "text-black text-lg"}
        ${labelClassName}`}
      >
        <PrismicTranslation field={label} simple />
      </label>
    )}
    <div>
      <input
        required={required}
        pattern={pattern}
        readOnly={readOnly}
        min={0}
        value={value}
        onChange={onChange}
        type={type ?? "text"}
        name={name || label}
        disabled={disabled}
        onKeyDown={onKeyDown}
        id={name || label}
        className={`p-3 block w-full border border-gray text-black hadow-sm placeholder:text-text sm:text-sm sm:leading-6 
        ${isError && "border-red border[1.5px] bg-red-200"}
        ${readOnly && "border-none shadow-none focus-visible:outline-0 cursor-auto"}
        ${className}`}
        placeholder={placeholder || "..."}
      />
      {isError && (
        <p className="text-red text-sm my-2">
          <PrismicTranslation field={errorLabel || "field_error"} simple />
        </p>
      )}
    </div>
  </div>
);
