import { FC, useMemo } from "react";
import PrismicTranslation from "../PrismicTranslation";
import Title from "../ui/Title";
import { CartProduct, SizingObject } from "../../types";
import { getMininumOrderQuantity, getSettingsNumberValue } from "../../utils";
import { SizeChart } from "./SizeChart";
import { OrderQuantityGroup } from "./OrderQuantityGroup";
import {orderQuantityWarnings} from "./utils";

export const OrderQuantity: FC<{
  cartItem: CartProduct;
  handleCartAmount: Function;
  orderQuantity: number;
  settings: any;
  sizeChart?: string;
}> = ({ cartItem, handleCartAmount, orderQuantity, settings, sizeChart }) => {
  // Define constants for the minimum order quantities
  const MINIMUM_TOTAL_QUANTITY = getMininumOrderQuantity(settings); // Overall minimum
  const minimumQuantityGender = getSettingsNumberValue(
    settings,
    "minimum_quantity_gender",
    25,
  ); // Per-sizingObject minimum

  // Create translations options for each gender with less then required item count
  const genderAmountWarnings = useMemo(() => {
    return orderQuantityWarnings(cartItem, minimumQuantityGender);
  }, [cartItem, minimumQuantityGender]);

  const genderGroups = useMemo(() => {
    const result = new Set<string>();
    cartItem.amount.forEach((amount: SizingObject) => {
      if (amount.primary.gender) {
        result.add(amount.primary.gender);
      }
    });
    return Array.from(result);
  }, [cartItem]);

  return (
    <div className="col-span-6 md:col-span-4">
      <div className="flex gap-2 ">
        <Title label="choose_size" />
        <SizeChart sizeChart={sizeChart} />
      </div>

      <div className="flex flex-wrap gap-4">
        {/* Warning for overall minimum order quantity */}
        {orderQuantity > 0 && orderQuantity < MINIMUM_TOTAL_QUANTITY && (
          <span className="text-red font-bold border border-red p-4 bg-red/10 rounded">
            <PrismicTranslation
              field="min_order_quantity"
              options={{ min_order_quantity: MINIMUM_TOTAL_QUANTITY }}
              simple
            />
          </span>
        )}

        {/* Order quantity for each gender group */}
        {genderGroups.map((groupName) => (
          <div className="w-full" key={groupName}>
            <div className="font-bold text-primary">
              <PrismicTranslation field={groupName} simple />
            </div>

            <OrderQuantityGroup
              cartItem={cartItem}
              handleCartAmount={handleCartAmount}
              groupName={groupName}
              groupNames={genderGroups}
              genderAmountWarnings={genderAmountWarnings}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
