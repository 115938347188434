import {getFabricSupplier} from "./components/Cart/utils";
import {
  CartItemPriceDetail,
  CartProduct,
  ConfigLoader,
  Country,
  Fabric,
  OrderInformation,
  Product,
  ProductGroup,
  ProductOption,
  ProjectLogo,
  SettingsContainer,
  SettingsData,
  SizingObject,
  Supplier,
} from "./types";

export const TAX_RATE = 0.21;
const verbose = 0;

const uuidPattern =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const getSettingsNumberValue = (settings: Array<SettingsContainer>, key:keyof SettingsData, fallback: number = 0):number => {
  if (!settings || !settings.length) {
    return fallback;
  }
  return settings[0].data[key] || fallback;
};

export const getMininumOrderQuantity = (settings: Array<SettingsContainer>):number => {
  return  getSettingsNumberValue(settings, "minimum_quantity", 0);
};

export const getShippingCosts = (settings: Array<SettingsContainer>):number => {
  return  getSettingsNumberValue(settings, "shipping_cost", 0);
};

// get price for one product including all options and fabrics
export const getCartItemPrice = (
  cartItem: CartProduct,
): number => {
  const detail = getCartItemPriceDetail(cartItem);
  return detail.totalPrice;
};

// get total amount of items for all gender options and sizes for one product
export const getSizesAmount = (cartItem: CartProduct) => {
  let total = 0;
  cartItem.amount?.forEach((genderOption: SizingObject) => {
    genderOption.items?.forEach((size) => {
      total += size.amount || 0;
    });
  });
  return total;
};

// get price for total of all sizes for one product
export const getCartItemSizesPrice = (
  cartItem: CartProduct,
) => {
  const sizesAmount = getSizesAmount(cartItem);
  const price = getCartItemPrice(cartItem);
  return price * sizesAmount;
};

// Get price for total cart items exl taxes and shipping
export const getCartPrice = (
  products: Array<CartProduct>,
):number =>
products?.reduce((acc: any, cartItem: any) => {
  return acc + getCartItemSizesPrice(cartItem);
}, 0 ) || 0;

// calculate taxes for cart price ( default 21% )
export const getCartTaxes = (cartPrice: any): number => cartPrice * TAX_RATE;

// get cart total price including taxes and shipping , apply discount if present
export const getCartTotal = (
  products: any,
  settings: any,
  discount = 0,
): {
    discountAmount: number,
    shippingCost: number,
    subtotal: number,
    taxes: number,
    totalPrice:number,
  } => {

  const subtotal = getCartPrice(products);
  const taxes = getCartTaxes(subtotal);
  const shippingCost = getShippingCosts(settings);
  const total = subtotal + taxes + shippingCost;
  const discountAmount = getPriceDiscount(total, discount);

  return {
    discountAmount,
    shippingCost,
    subtotal,
    taxes,
    totalPrice: total - discountAmount,
  }
};

// get discount amount of a price
export const getPriceDiscount = (price: number|undefined, discount = 0): number => {
  if (!price) {
    return 0;
  }
  return price * (discount / 100);
};

export const totalCartProductAmount = (cartItem: CartProduct) => {
  if (!cartItem) return 0;
  return getSizesAmount(cartItem);
};

const getPriorityForProductOption = (
  productOption: ProductOption,
  product: Product,
): number => {
  return (
    product.data.product_options.find(
      (option: any) => option.product_option.id === productOption.id,
    )?.priority || Infinity
  );
};

export const sortProductOptionsByPriority = (product: Product) => {
  return (a: ProductOption, b: ProductOption) => {
    const aPriority = getPriorityForProductOption(a, product);
    const bPriority = getPriorityForProductOption(b, product);
    return aPriority - bPriority;
  };
};

export const updateProductOption = (
  productOption: ProductOption,
  productOptions: ProductOption[],
): ProductOption[] => {
  return productOptions.map((curr) => {
    if (curr.id === productOption.id) {
      return productOption;
    }
    return curr;
  });
};

export const isProductOptionWithLogo = (productOption: ProductOption) =>
  productOption.data.meshes.some((mesh) => mesh.variant === "logo_mesh");

// Get ProductOption variant price
// Returns false no variant price is available
// Returns null if no variant price is found
// Current implementation only takes ProductLogo variant prices into account
export const calcProductOptionVariantPrice = (
  productOption: ProductOption,
  projectLogos: ProjectLogo[],
  fabricSupplier: Supplier | null,
) => {
  if (!productOption) {
    return false;
  }
  if (fabricSupplier && productOption.data.prices) {
    const supplierPrice = productOption.data.prices.find(
      (curr) =>
        curr.supplier.id === fabricSupplier.id &&
        typeof curr.supplier_price === "number",
    );
    if (supplierPrice) {
      return supplierPrice.supplier_price;
    }
  }
  if (!Array.isArray(productOption.data.variants)) {
    return false;
  }
  if (productOption.data.logo_option) {
    const projectLogo = projectLogos.find(
      (curr) => curr.logoName === productOption.data.logo_option,
    );
    if (projectLogo && projectLogo.logoOption) {
      const variantName = projectLogo.logoOption.toLowerCase();
      const variant = productOption.data.variants.find(
        (curr) => curr.variant_name.toLowerCase() === variantName,
      );
      if (variant?.variant_price) {
        return variant.variant_price;
      }
    }
    return 0;
  }
  return false;
};

// convert saved product config from database to cart item
export const convertLoadedProductToCartItem = (
  productConfig: ConfigLoader,
  products: Product[],
  productGroups: ProductGroup[],
  productOptions: ProductOption[],
  fabrics: Fabric[],
): CartProduct | null => {
  const product = products?.find(
    (product: Product) => product.id === productConfig.product,
  );
  const productGroup = productGroups?.find(
    (productGroup: ProductGroup) =>
      productGroup.id === productConfig.productGroup,
  );
  if (!product || !productGroup) {
    return null;
  }
  const selectedProductOptions = [] as ProductOption[];
  productConfig.productOptions?.forEach((productOption) => {
    const option = productOptions?.find(
      (option: ProductOption) => option.id === productOption.id,
    );
    if (option) {
      const newProductOption = {
        ...option,
        data: {
          ...option.data,
          color_option: productOption.colorOption || option.data.color_option,
          logo_option: productOption.logoOption || option.data.logo_option,
        },
      }
      if (isProductOptionWithLogo(newProductOption) && !newProductOption.data.logo_option) {
        newProductOption.data.logo_option = 'logo_1'; 
      }
      selectedProductOptions.push(newProductOption);
    }
  });

  return {
    id: productConfig.id,
    product,
    productGroup,
    fabric: {
      main: fabrics?.find(
        (fabric: Fabric) => fabric.id === productConfig.mainFabric,
      ),
      contrast: fabrics?.find(
        (fabric: Fabric) => fabric.id === productConfig.contrastFabric,
      ),
    },
    productOptions: selectedProductOptions,
    projectLogos: productConfig.projectLogos,
    productModelPicture: productConfig.productModelPicture,
    amount: product?.data?.body,
  };
};

// map user and customer data to order information
export const getDefaultOrderInformation = (
  customer: any,
  user: any,
  orderOptions: any,
) => {
  return {
    companyName: customer?.name || "",
    vat: customer?.vat || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phone: user?.phone_number || "",
    billingAddress: {
      street: orderOptions?.invoiceAdress?.street || "",
      street2: orderOptions?.invoiceAdress?.street2 || "",
      zipCode: orderOptions?.invoiceAdress?.zipCode || "",
      city: orderOptions?.invoiceAdress?.city || "",
    },
  } as OrderInformation;
};

// get the translated property from prismic data
// example : getPrismicTranslation('fr', 'name', data) => data.name_fr
export const getPrismicTranslation = (
  locale: string,
  property: string,
  data: any,
) => {
  if (!data) return "";
  const translatedProperty = `${property}_${locale}`;
  return data[translatedProperty] || data[property];
};

// Get product feature prices based on fabric supplier. Returns false if no fabricSupplier is provided or no fabricSupplier price is found.
export function getProductFeatureFabricPrice(
  productOption: ProductOption,
  fabricSupplier: Supplier | null,
): number | boolean {
  if (
    productOption &&
    fabricSupplier &&
    productOption.data.prices &&
    productOption.data.prices.find(
      (curr) =>
        curr.supplier.id === fabricSupplier.id &&
        typeof curr.supplier_price === "number",
    )
  ) {
    const price = productOption.data.prices.find(
      (curr) => curr.supplier.id === fabricSupplier.id,
    );
    if (price) {
      return price.supplier_price;
    }
  }
  return false;
}

// Get Product price based on fabric supplier. Returns false if no fabricSupplier is provided or no fabricSupplier price is found.
export function getProductFabricPrice(
  product: Product,
  fabricSupplier: Supplier | null,
): number | boolean {
  if (
    product &&
    fabricSupplier &&
    product.data.prices &&
    product.data.prices.find(
      (curr) =>
        curr.supplier.id === fabricSupplier.id &&
        typeof curr.supplier_price === "number",
    )
  ) {
    const price = product.data.prices.find(
      (curr) => curr.supplier.id === fabricSupplier.id,
    );
    if (price) {
      return price.supplier_price;
    }
  }
  return false;
}

// Define a mapping from country codes to language names
export const languageMapping: Record<Country, string> = {
  en: "English",
  nl: "Nederlands",
  de: "Deutsch",
  fr: "Français",
};

export function isValidProjectIdFormat(projectId: string | undefined): boolean {
  if (projectId && uuidPattern.test(projectId)) {
    return true;
  }
  return false;
}

// Get price for one product including all options and fabrics
export const getCartItemPriceDetail = (
  cartItem: CartProduct,
): CartItemPriceDetail => {
  const detail:CartItemPriceDetail = {
    totalPrice: 0,
    productGroupPrice: undefined, // current productGroup has no price
  }

  if (!cartItem || !cartItem.product) {
    detail.totalPrice = 0;
    return detail;
  }

  const fabricSupplier = getFabricSupplier(cartItem);
  const projectLogos = cartItem.projectLogos || [];
  detail.cartProductId = cartItem.id;
  detail.fabricSupplierId = fabricSupplier?.id;

  // Product price
  if (fabricSupplier) {
    const productFabricPrice = getProductFabricPrice(
      cartItem.product,
      fabricSupplier,
    );
    if (typeof productFabricPrice === "number") {
      detail.productPrice = productFabricPrice;
    } else {
      detail.productPrice = cartItem.product?.data?.price || 0;
    }
  } else {
    detail.productPrice = cartItem.product?.data?.price || 0;
  }
  // Fabric prices
  detail.fabricMainPrice = cartItem.fabric?.main?.data?.price || 0;
  detail.fabricContrastPrice = cartItem.fabric?.contrast?.data?.price || 0;

  // Product Features prices with fabric supplier
  if (fabricSupplier) {
    detail.productOptionPrices = cartItem.productOptions?.map(
      (option: ProductOption) => {
        const productFeatureFabricPrice = getProductFeatureFabricPrice(
          option,
          fabricSupplier,
        );

        if (typeof productFeatureFabricPrice === "number") {
          return {id: option.id, price: productFeatureFabricPrice, logo_option: isProductOptionWithLogo(option) };
        }
        if(isProductOptionWithLogo(option)) {
          const variantPrice = calcProductOptionVariantPrice(
            option,
            projectLogos,
            fabricSupplier,
          );
          if (variantPrice !== false) {
            return {id: option.id, price: variantPrice, logo_option: true };
          }
        }
        return {id: option.id, price: option?.data?.price || 0 };
      },
    );
    // Product Features prices without fabric supplier
  } else {
      detail.productOptionPrices = cartItem.productOptions?.map(
        (option: ProductOption) => {
          if(isProductOptionWithLogo(option)) {
            const variantPrice = calcProductOptionVariantPrice(
              option,
              projectLogos,
              fabricSupplier,
            );
            if (variantPrice !== false) {
              return {id: option.id, price: variantPrice, logo_option: true };
            }
          }
          return {id: option.id, price: option?.data?.price || 0};
        },
      );
  }

  const prices: number[] = [
    detail.productPrice,
    detail.fabricMainPrice,
    detail.fabricContrastPrice,
    ...(detail.productOptionPrices?.map(opt => opt.price) || []),
  ].filter((value): value is number => value !== undefined);

  const totalPrice = prices.flat().reduce((acc, curr) => {
    if (typeof curr === "number" && typeof acc === "number") {
      return acc + curr;
    }
    return acc;
  }, 0) as number;

  detail.prices = prices;
  detail.totalPrice = totalPrice;

  if (verbose > 0) {
    console.log(
      'Cart Item',
      cartItem.id,
      cartItem.product.data.name,
      'Unit Price',
      detail.prices.flat(),
      'total price is',
      detail.totalPrice,
      'fabricSupplier is ',
      fabricSupplier?.id,
    );
  }
  return detail;

};
