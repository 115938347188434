import { FC, useState } from "react";
import PrismicTranslation from "../PrismicTranslation";
import Modal from "../ui/Modal";
import SizeIcon from "../ui/Icons/SizeIcon";

export const SizeChart: FC<{
  sizeChart?: string;
}> = ({ sizeChart }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      {sizeChart && (
        <button
          onClick={() => setModalOpen(true)}
          className="flex items-center gap-2 text-sm border border-neutrals-300 rounded-full  bg-white p-2 pr-3.5  hover:bg-primary-200 hover:border-primary-300"
        >
          <SizeIcon width={16} height={16} />
          <PrismicTranslation field="view_size_chart" simple />
        </button>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        size="large"
      >
        <img src={sizeChart} alt="Size Chart" className="w-full h-auto" />
      </Modal>
    </>
  );
};
