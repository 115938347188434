import Title from "../ui/Title";
import React, { FC, useState, useRef, useEffect } from "react";
import { Distributor } from "../../types";
import { Input } from "../ui/Input";
import PrismicTranslation from "../PrismicTranslation";

interface Props {
  current?: Distributor;
  options: Distributor[];
  onSelect: (field: string, value?: Distributor) => void;
}

const DEMO_ID = 2028;

const DistributorSelect: FC<Props> = ({ onSelect, options, current }) => {
  const [searchTerm, setSearchTerm] = useState<string>(current?.name || "");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAlternative, setIsAlternative] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const distributorPlaceholder = PrismicTranslation({ field: "distributor_placeholder", simple: true });
  const alternativeLabel = PrismicTranslation({ field: "distributor_alternative", simple: true });
  const enterDistributorName = PrismicTranslation({ field: "distributor_alternative_name", simple: true });

  useEffect(() => {
    if (current) {
      setSearchTerm(current.name);
    }
  }, [current]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (option: Distributor) => {
    onSelect("distributor", option);
    setSearchTerm(option.name);
    setIsAlternative(false); // ✅ Uncheck checkbox if a distributor is selected
    setIsOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    setIsOpen(true);

    if (newValue.trim() === "") {
      // If input is empty, trigger onSelect to indicate invalidation
      onSelect("distributor", undefined);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  const handleAlternativeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setSearchTerm(newName);

    onSelect("distributor", {
      id: DEMO_ID,
      name: newName,
      address: "",
      zip: "",
      city: "",
      email: "",
      country: "",
    });
  };

  const handleCheckboxChange = () => {
    setIsAlternative((prev) => {
      const newAlternativeState = !prev;

      if (newAlternativeState) {
        // If checkbox is checked -> Use alternative distributor
        setSearchTerm("");
        setIsOpen(false);
        onSelect("distributor", {
          id: DEMO_ID,
          name: "",
          address: "",
          zip: "",
          city: "",
          email: "",
          country: "",
        });
      } else {
        // If checkbox is unchecked -> Reset to normal distributor
        onSelect("distributor", {
          id: DEMO_ID,
          name: "",
          address: "",
          zip: "",
          city: "",
          email: "",
          country: "",
        });
      }

      return newAlternativeState;
    });
  };

  const filteredOptions = () => {
    return options.filter((option) => {
      const distributor = `${option.name} ${option.address} ${option.zip} ${option.city} ${option.country}`;
      return distributor.toLowerCase().includes(searchTerm.toLowerCase());
    });
  };

  return (
    <div ref={dropdownRef} className="relative">
      <Title tertiary className="text-black" label="distributor" />

      <div className="relative">
        <Input
          type="search"
          placeholder={distributorPlaceholder}
          value={isAlternative ? "" : searchTerm}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onClick={() => setIsOpen(true)}
          aria-expanded={isOpen}
          disabled={isAlternative}
        />

        {isOpen && !isAlternative && (
          <ul
            role="listbox"
            className="border border-gray w-full bg-white absolute z-10 max-h-40 overflow-y-auto shadow-lg rounded-md"
          >
            {filteredOptions().map((option, index) => (
              <li
                key={index}
                onClick={() => handleSelect(option)}
                role="option"
                className={`cursor-pointer hover:bg-primary-200 p-2 hover:bg-gray-100 ${
                  current?.id === option.id ? "bg-primary-200" : ""
                }`}
              >
                <div>
                  {option.name} <br />
                  <span className="text-xs text-gray-600">
                    {option.address}, {option.zip} - {option.city}, <PrismicTranslation field={option.country} simple />
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="flex items-center gap-2 mb-2">
        <input
          type="checkbox"
          id="alternative-distributor"
          checked={isAlternative}
          onChange={handleCheckboxChange}
          className="cursor-pointer"
        />
        <label htmlFor="alternative-distributor" className="text-sm text-gray-700 cursor-pointer">
          {alternativeLabel}
        </label>
      </div>

      {isAlternative && (
        <Input
          type="text"
          required={true}
          placeholder={enterDistributorName}
          value={searchTerm}
          onChange={handleAlternativeChange}
          className="mt-2 border-gray-300 p-2 w-full"
        />
      )}
    </div>
  );
};

export default DistributorSelect;
