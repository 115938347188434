import Title from "../ui/Title";
import { getCartTotal } from "../../utils";
import { FC } from "react";
import { useAppSelector } from "../../hooks";
import { selectCart } from "../../store/reducers/cartReducer";
import { selectDiscount } from "../../store/reducers/userReducer";
import PrismicTranslation from "../PrismicTranslation";
import { useGetDocumentByTypeQuery } from "../../api/prismic";

interface Props {
  showWarning?: boolean;
  showTitle?: boolean;
}

const OrderSummary: FC<Props> = ({
  showTitle = false,
  showWarning = false,
}) => {
  const cart = useAppSelector(selectCart);
  const discount = useAppSelector(selectDiscount);
  const { data: settings } = useGetDocumentByTypeQuery({ type: "settings", lang: "en-us" });
  const cartTotal = getCartTotal(cart.products, settings, 0);
  const cartDiscount = cartTotal.discountAmount;

  return (
    <div className="flex-1 basis-auto overflow-y-scroll">
      {showTitle && <Title size={1} label={"summary"} />}
      <div className="space-y-2 mt-2 font-bold">
        <div className="flex items-center justify-between">
          <span>
            <PrismicTranslation field="subtotal" simple />
          </span>
          <div className="ml-auto">{cartTotal.subtotal.toFixed(2)}€</div>
        </div>
        <div className="flex items-center justify-between">
          <span>
            <PrismicTranslation field="taxes" simple />
          </span>
          <div className="ml-auto">{cartTotal.taxes.toFixed(2)}€</div>
        </div>
        {discount && (
          <div className="flex items-center justify-between">
            <span>
              <PrismicTranslation field="discount" simple />
            </span>
            <div className="ml-auto">{discount.toFixed(0)}%</div>
          </div>
        )}
        <div className="flex items-center justify-between">
          <div>
            <span>
              <PrismicTranslation field="shipping" simple />
            </span>

            <p className="text-text text-sm max-w-sm font-normal mt-2">
              <PrismicTranslation field="shipping_description" simple />
            </p>
          </div>
          <div className="self-start">
            <div className="ml-auto">{cartTotal.shippingCost}€</div>
          </div>
        </div>
        <div className="border border-gray w-full my-4" />
        <div className="flex items-center justify-between">
          <span>
            <PrismicTranslation field="total" simple />
          </span>
          <div className="ml-auto">
            <div className={`${discount ? "line-through" : ""}`}>
              {cartTotal.totalPrice.toFixed(2)}€
            </div>
            {discount && <div>{(cartTotal.totalPrice - cartDiscount).toFixed(2)}€</div>}
          </div>
        </div>
      </div>
      {showWarning && (
        <div className="p-4 mt-2 bg-warning font-medium text-sm">
          <PrismicTranslation field="order_summary_warning" simple />
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
