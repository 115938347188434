import { FC, useMemo } from "react";
import { useRemoveProductConfigMutation } from "../../api/nest";
import { useGetDocumentByTypeQuery } from "../../api/prismic";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { removeFromCart, updateAmount } from "../../store/reducers/cartReducer";
import { selectProjectId } from "../../store/reducers/configReducer";
import { selectLanguage } from "../../store/reducers/uiReducer";
import { CartProduct, Fabric, ProductOption, Supplier } from "../../types";
import { getPrismicTranslation, totalCartProductAmount } from "../../utils";
import PrismicTranslation from "../PrismicTranslation";
import { Button } from "../ui/Button";
import Title from "../ui/Title";
import { DuplicateDesign } from "./DuplicateDesign";
import { EditDesign } from "./EditDesign";
import { OrderQuantity } from "./OrderQuantity";
import { CartItemPrice } from "./CartItemPrice";
import { CartItemSizesPrice } from "./CartItemSizesPrice";
import {getFabricSupplier} from "./utils";

const verbose = 0;

interface CartItemProps {
  cartItem: CartProduct;
}

const CartItem: FC<CartItemProps> = ({ cartItem }) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const projectId = useAppSelector(selectProjectId);

  const fabricSupplier: Supplier | null = useMemo(() => {
    return getFabricSupplier(cartItem);
  }, [cartItem]);

  const orderQuantity = totalCartProductAmount(cartItem);
  const { data: settings } = useGetDocumentByTypeQuery({
    type: "settings",
    lang: "en-us",
  });

  const [removeProductConfig] = useRemoveProductConfigMutation();

  const remove = async () => {
    await removeProductConfig({
      cartItem: cartItem.id,
      project: projectId || "",
    });
    dispatch(removeFromCart(cartItem));
  };

  // "variant" is regular, long, short, ...
  const handleCartAmount = (
    variant: string,
    gender: string,
    size: any,
    amount: number,
  ) => {
    dispatch(updateAmount({ variant, gender, id: cartItem.id, size, amount }));
  };

  return (
    <div className="w-full grid grid-cols-6 bg-white p-4 border border-gray gap-x-3 relative">
      <div className="flex col-span-6  md:col-span-2">
        <div className="flex pr-2">
          <img
            className="w-48 h-48 object-contain"
            src={cartItem.productModelPicture || "/placeholder.png"}
            alt={getPrismicTranslation(
              language,
              "name",
              cartItem?.product?.data,
            )}
          />
        </div>
        <div className="flex flex-col flex-wrap gap-2">
          <Title
            label={getPrismicTranslation(
              language,
              "name",
              cartItem?.productGroup?.data,
            )}
          />
          {verbose > 0 && (
            <>
              <div className="verbose">{cartItem.id}</div>
              <div className="verbose">
                fabricSupplier:&nbsp;
                {fabricSupplier?.id}
              </div>
            </>
          )}
          <span className="font-bold">
            <CartItemPrice cartItem={cartItem} />
          </span>
          <p className="text-text gap-2 mb-2">
            <PrismicTranslation field={"unit_price_features"} simple />
          </p>
          <div className="flex flex-wrap gap-2">
            <strong>
              <PrismicTranslation field={"product_fit"} simple />
            </strong>
            <span className="text-text">
              {getPrismicTranslation(language, "name", cartItem?.product?.data)}
            </span>
          </div>
          <div className="flex flex-wrap items-center gap-2">
            <strong>
              <PrismicTranslation field={"main_color"} simple />
            </strong>
            <div
              className="w-2 h-2 rounded-full border"
              style={{
                backgroundColor: (cartItem.fabric?.main as Fabric)?.data
                  .web_format,
              }}
            />
            <span className="text-text">
              {getPrismicTranslation(
                language,
                "name",
                cartItem?.fabric?.main?.data,
              )}
            </span>

            {verbose > 0 && (
              <>
                <div className="verbose">{cartItem.fabric?.main?.id}</div>
                <div className="verbose">
                  {cartItem.fabric?.main?.data?.supplier?.id}
                </div>
              </>
            )}
          </div>
          <div className="flex flex-wrap items-center gap-2">
            <strong>
              <PrismicTranslation field={"contrast_color"} simple />
            </strong>
            <div
              className="w-2 h-2 rounded-full border"
              style={{
                backgroundColor: (cartItem.fabric?.contrast as Fabric)?.data
                  .web_format,
              }}
            />
            <span className="text-text">
              {getPrismicTranslation(
                language,
                "name",
                cartItem?.fabric?.contrast?.data,
              )}
            </span>

            {verbose > 0 && (
              <>
                <div className="verbose">{cartItem.fabric?.contrast?.id}</div>
                <div className="verbose">
                  {cartItem.fabric?.contrast?.data?.supplier?.id}
                </div>
              </>
            )}
          </div>
          <div className="flex flex-wrap gap-2">
            <strong>
              <PrismicTranslation field={"product_options"} simple />:
            </strong>
            <ul>
              {cartItem.productOptions?.map((option: ProductOption) => (
                <li key={option.id} className="text-text">
                  {getPrismicTranslation(language, "name", option?.data)}
                </li>
              ))}
            </ul>
          </div>
          <div className="text-text mt-1 flex flex-col">
            <EditDesign cartItem={cartItem} />
            <DuplicateDesign cartItem={cartItem} />
            <Button
              onClick={remove}
              className="text-text mt2  cursor-pointer"
              icon="delete"
              label="delete"
              link
            />
          </div>
        </div>
      </div>
      <OrderQuantity
        cartItem={cartItem}
        handleCartAmount={handleCartAmount}
        orderQuantity={orderQuantity}
        settings={settings}
        sizeChart={cartItem.product?.data?.size_chart?.url}
      />
      <div className="font-bold flex gap-2 md:block absolute right-4 top-4">
        <CartItemSizesPrice cartItem={cartItem} />
      </div>
    </div>
  );
};

export default CartItem;
