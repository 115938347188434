import { FC, Suspense, useContext, useEffect, useRef, useState } from "react";
import { Color3, Vector3 } from "@babylonjs/core/Maths/math";
import { ILoadedModel, Model, SceneLoaderContextProvider, SceneContext } from "react-babylonjs";
import { ProductOption } from "../../types";
import {
  getAllDisplayableProductOptionMeshes,
  getAllProductOptionDisabledMeshes,
  setMeshColors,
  setMeshColorByName,
  getArrayDiff,
  getAllProductOptionMeshesFabrics,
} from "./utils";
import { AbstractMesh, ArcFollowCamera, PBRMaterial, Scene } from "@babylonjs/core";
import { verbose } from "./config";

export type ProductModelType = {
  center: Vector3;
  rootUrl: string;
  sceneFilename: string | undefined;
  onModelLoaded?: (model: ILoadedModel) => void;
  onModelError?: (model: ILoadedModel) => void;
  onModelLoadProgress?: () => void;
  // Showing and hidding meshes should be handeled by the parent component
  onEnableMeshes: (scene: Scene | null, meshNames: string[], value: boolean, force?: boolean) => void;
  selectedProductOptions: Array<ProductOption> | undefined;
  allProductOptionMeshes: Array<string> | null;
  allProductOptionsDisabledMeshes: Array<string>;
  mainColorMeshNames: Array<string>;
  contrastColorMeshNames: Array<string>;
  staticColorMeshNames: Array<string>;
  fabricColorMap: Map<string, Color3>;
  cameraRadius: number;
};

const modelName = "product-model";

const ProductModel: FC<ProductModelType> = (props) => {
  const {
    selectedProductOptions,
    allProductOptionMeshes,
    allProductOptionsDisabledMeshes,
    sceneFilename,
    rootUrl,
    mainColorMeshNames,
    contrastColorMeshNames,
    staticColorMeshNames,
    fabricColorMap,
    cameraRadius,
    onEnableMeshes,
  } = props;
  const ctx = useContext(SceneContext);
  const modelsMap = useRef(new Map<string, ILoadedModel>()); // to store the loaded models
  const [sceneLoaded, setSceneLoaded] = useState<boolean>(false);

  function onModelLoaded(model: ILoadedModel) {
    verbose > 0 && console.log("[onModelLoaded] with rootUrl:", rootUrl, "and sceneFilename:", sceneFilename);
    if (!sceneFilename) {
      return; // return early if sceneFilename is undefined
    }

    // Dispose of all other models
    modelsMap.current.forEach((otherModel, key) => {
      if (key !== sceneFilename) {
        otherModel.rootMesh?.dispose();
        modelsMap.current.delete(key);
      }
    });

    // Add the new model to the map
    modelsMap.current.set(sceneFilename, model);
  }

  function onCreated(_model: AbstractMesh) {
    if (!sceneFilename) {
      return; // return early if sceneFilename is undefined
    }

    const currentModel = modelsMap.current.get(sceneFilename);
    if (currentModel) {
      props.onModelLoaded && props.onModelLoaded(currentModel);
      setSceneLoaded(true);
    }
  }

  useEffect(() => {
    verbose > 0 && console.log("[useEffect] Load model with rootUrl:", rootUrl, "and sceneFilename:", sceneFilename);
    setSceneLoaded(false);
    modelsMap.current.forEach((otherModel) => {
      otherModel.rootMesh?.dispose();
    });
    modelsMap.current.clear();
  }, [sceneFilename, rootUrl]); // add 'rootUrl' dependency because it has the ProductVariant and a model reload is needed.

  // ProductOption updates
  useEffect(() => {
    if (!sceneLoaded || !selectedProductOptions) {
      return;
    }

    // Show selected ProductOption meshes
    const meshNames = getAllDisplayableProductOptionMeshes(selectedProductOptions);
    onEnableMeshes(ctx.scene, meshNames, true);

    // Hide unselected ProductOption meshes
    if (allProductOptionMeshes) {
      const disabledProductOptionsMeshRefs = getArrayDiff(allProductOptionMeshes, meshNames);
      onEnableMeshes(ctx.scene, disabledProductOptionsMeshRefs, false);
    }
    // Hide ProductOption "disable_meshes" for selected ProductOptions
    const productOptionDisabledMeshes = getAllProductOptionDisabledMeshes(selectedProductOptions);
    onEnableMeshes(ctx.scene, productOptionDisabledMeshes, false);

    // Show unselected ProductOption "disable_meshes"
    if (allProductOptionsDisabledMeshes) {
      const showDisabledMeshes = getArrayDiff(allProductOptionsDisabledMeshes, productOptionDisabledMeshes);
      onEnableMeshes(ctx.scene, showDisabledMeshes, true);
    }
  }, [
    sceneLoaded,
    ctx.scene,
    selectedProductOptions,
    allProductOptionMeshes,
    allProductOptionsDisabledMeshes,
    onEnableMeshes,
  ]);

  // Product Mesh updates
  useEffect(() => {
    if (!sceneLoaded || !selectedProductOptions) {
      return;
    }
    // Get all active ProductOption meshes (enabled + disabled)
    const selectedProductOptionsMeshNames = [
      ...getAllDisplayableProductOptionMeshes(selectedProductOptions),
      ...getAllProductOptionDisabledMeshes(selectedProductOptions),
    ];

    // Show and update fabric color for all Product meshes that are in in active ProductOption meshes
    const productMeshNames_Main = getArrayDiff(mainColorMeshNames, selectedProductOptionsMeshNames);
    onEnableMeshes(ctx.scene, productMeshNames_Main, true);
    setMeshColors(ctx.scene, productMeshNames_Main, fabricColorMap.get("main"));

    // Show and update fabric color for all Product meshes that are in in active ProductOption meshes
    const productMeshNames_Contrast = getArrayDiff(contrastColorMeshNames, selectedProductOptionsMeshNames);
    onEnableMeshes(ctx.scene, productMeshNames_Contrast, true);
    setMeshColors(ctx.scene, productMeshNames_Contrast, fabricColorMap.get("contrast"));

    // Show and update fabric color for all Product meshes that are in in active ProductOption meshes
    const productMeshNames_Static = getArrayDiff(staticColorMeshNames, selectedProductOptionsMeshNames);
    onEnableMeshes(ctx.scene, productMeshNames_Static, true);
    setMeshColors(ctx.scene, productMeshNames_Static, Color3.FromHexString("#000000"));

    verbose > 0 && console.log("Show product meshes with Main fabric color", productMeshNames_Main);
    verbose > 0 && console.log("Show product meshes with Contrast fabric color", productMeshNames_Contrast);
    verbose > 0 && console.log("Show product meshes with Static Black color", productMeshNames_Static);
  }, [
    sceneLoaded,
    ctx.scene,
    mainColorMeshNames,
    contrastColorMeshNames,
    selectedProductOptions,
    fabricColorMap,
    onEnableMeshes,
  ]);

  // Fabric Color updates
  useEffect(() => {
    if (!sceneLoaded || !ctx.scene) {
      return;
    }
    // Set Fabric color for Product meshes
    setMeshColors(ctx.scene, mainColorMeshNames, fabricColorMap.get("main"));
    setMeshColors(ctx.scene, contrastColorMeshNames, fabricColorMap.get("contrast"));

    // Set Fabric color for ProductOption meshes
    if (selectedProductOptions) {
      // All ProductOption mesh names and fabric color name
      const allProductOptionMeshesFabrics = getAllProductOptionMeshesFabrics(selectedProductOptions);
      // Set ProductOption mesh fabric color
      setMeshColorByName(ctx.scene, allProductOptionMeshesFabrics, fabricColorMap);

      // !! Set ProductOption mesh names that include a material filter (as "mesh_name:material_name")
      // after all the other ProductOption mesh names to override them
      const withMaterialFilter = new Map(
        Array.from(allProductOptionMeshesFabrics.entries()).filter(([key, _value]) => key.includes(":")),
      );
      setMeshColorByName(ctx.scene, withMaterialFilter, fabricColorMap);
    }
  }, [sceneLoaded, ctx.scene, mainColorMeshNames, contrastColorMeshNames, selectedProductOptions, fabricColorMap]);

  useEffect(() => {
    if (!sceneLoaded || !ctx.scene) {
      return;
    }
    // Set Bumpmap strength on all materials
    for (const mat of ctx.scene.materials) {
      if ("bumpTexture" in mat) {
        const pbrmat = mat as PBRMaterial;
        if (pbrmat.bumpTexture) {
          pbrmat.bumpTexture.level = 0.5;
        }
      }
    }

    // @TODO: temp fix to get the camera to update to the set values (radius and beta) after loading a mesh model.
    // The values are set on the react Camera component but are not present in the next renderers.
    const camera = ctx.scene.getNodeByName("product-camera1") as ArcFollowCamera;
    const model = ctx.scene.getNodeByName(modelName);
    camera.beta = Math.PI / 2;
    camera.radius = cameraRadius;
    window.setTimeout(() => {
      model?.setEnabled(true);
    });

    // React-babylonjs has issues with multiple cameras (second camera is for product model pictures)
    ctx.scene.setActiveCameraByName("product-camera1");
  }, [sceneLoaded, ctx.scene, cameraRadius]);

  // Wait for all ProductOption mesh references before loading the model.
  if (!sceneFilename || typeof sceneFilename === "undefined" || sceneFilename.length === 0 || !allProductOptionMeshes) {
    console.warn("No sceneFilename for product.");
    return null;
  }

  return (
    <SceneLoaderContextProvider>
      <Suspense>
        <Model
          name={modelName}
          position={props.center}
          rootUrl={props.rootUrl}
          sceneFilename={sceneFilename}
          onModelLoaded={onModelLoaded}
          onModelError={props.onModelError}
          onCreated={onCreated}
          setEnabled={false}
        />
      </Suspense>
    </SceneLoaderContextProvider>
  );
};

export { ProductModel };
