import { CartProduct, SizingObject, Supplier } from "../../types";

// Get genders in order quantity that do not have the minimum order quantity for a gender
export const orderQuantityWarnings = (
  cartItem: CartProduct,
  minimumQuantityGender: number,
): Array<{ gender: string; remaining: number; count: number }> => {
  const genderAmountSummary = new Map<string, number>();

  cartItem.amount.forEach((amount: SizingObject) => {
    if (amount.primary.gender) {
      const variantSubAmount = amount.items
        .filter((item) => item.amount)
        .map((item) => item.amount);
      variantSubAmount.push(
        genderAmountSummary.get(amount.primary.gender) || 0,
      );
      genderAmountSummary.set(
        amount.primary.gender,
        variantSubAmount.reduce((acc, curr) => {
          return (acc || 0) + (curr || 0);
        }, 0) || 0,
      );
    }
  });
  return Array.from(genderAmountSummary.keys())
    .filter((key) => {
      const value = genderAmountSummary.get(key);
      if (typeof value === "number" && value < minimumQuantityGender) {
        return genderAmountSummary.get(key);
      }
      return false;
    })
    .map((key) => ({
      gender: key,
      count: genderAmountSummary.get(key) || 0,
      remaining: minimumQuantityGender - (genderAmountSummary.get(key) || 0),
    }));
};

export const getFabricSupplier = (cartItem:CartProduct|null): Supplier | null => {
  return cartItem?.fabric?.main?.data?.supplier || null;
};
