import { belgium, checkVAT, france, germany, luxembourg, netherlands } from "jsvat";
import * as z from "zod";

export const isValidVatNumber = (input: string) => {
  return checkVAT(input, [belgium, netherlands, france, germany, luxembourg]).isValidFormat;
};

export const validVatNumber = z
  .string()
  .min(1, "shared_form_validation_invalidvatvumber")
  .refine((val) => isValidVatNumber(val), {
    message: "shared_form_validation_invalidVatNumber",
  });

export const validAddress = z.object({
  street: z.string().min(1, "shared_form_validation_nonempty"),
  houseNumber: z.string().optional(),
  suffix: z.string().optional(),
  bus: z.string().optional(),
  zipCode: z.string().min(1, "shared_form_validation_nonempty"),
  city: z.string().min(1, "shared_form_validation_nonempty"),
});

export const validGuestOrderInformationSchema = z.object({
  email: z.string().email("shared_form_validation_invalidemail"),
  distributor: z.object({
    name: z.string().min(2, "shared_form_validation_nonempty"),
  }),
});

export const validOrderInformationSchema = z.object({
  companyName: z.string().optional(),
  firstName: z.string().optional(),
  email: z.string().optional(),
  billingAddress: validAddress.optional(),
  shippingAddress: validAddress.optional(),
});
