import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { CartProduct, Size, SizingObject } from "../../types";
import { getMininumOrderQuantity, getSettingsNumberValue, totalCartProductAmount } from "../../utils";
import {orderQuantityWarnings} from "../../components/Cart/utils";

interface CartState {
  products: CartProduct[];
}

const initialState: CartState = {
  products: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetCart: (state) => {
      state.products = [];
    },
    addToCart: (state, action: PayloadAction<CartProduct>) => {
      state.products = [...state.products, action.payload];
    },
    updateProduct: (
      state,
      action: PayloadAction<{ id: string; cartProduct: Partial<CartProduct> }>,
    ) => {
      const { id, cartProduct } = action.payload;
      // Find the index of the CartProduct to update
      const index = state.products.findIndex((item) => item.id === id);

      // Ensure the CartProduct exists
      if (index !== -1) {
        const newCartProduct = { ...state.products[index], ...cartProduct };
        // Update the CartProduct while keeping it at the same index
        state.products[index] = newCartProduct;
      }
    },
    removeFromCart: (state, action: PayloadAction<CartProduct>) => {
      const hasProduct = state.products?.find(
        (product) => product.id === action.payload.id,
      );
      if (hasProduct) {
        state.products = state.products?.filter(
          (product) => product.id !== action.payload.id,
        );
      }
    },
    updateAmount: (
      state,
      action: PayloadAction<{
        variant: string;
        gender: string;
        id: string;
        size: any;
        amount: number;
      }>,
    ) => {
      const hasProduct = state.products?.find(
        (product) => product.id === action.payload.id,
      );
      if (hasProduct) {
        state.products = state.products?.map((product) => {
          if (product.id === action.payload.id) {
            const productAmount = product.amount?.find(
              (sizingObject: SizingObject) =>
                sizingObject.primary.translation_key === action.payload.variant &&
                sizingObject.primary.gender === action.payload.gender,
            );
            const sizeOption = productAmount?.items.find(
              (optionSize: Size) => optionSize.size === action.payload.size,
            );
            if (sizeOption) {
              sizeOption.amount = action.payload.amount;
            }
          }
          return product;
        });
      }
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  updateAmount,
  updateProduct,
  resetCart,
} = cartSlice.actions;

export const selectCart = (state: RootState) => state.cart;
export const selectCartItems = (state: RootState) => state.cart.products;
export const selectLatestCartItem = (state: RootState) => {
  const cart = state.cart.products;
  return cart[cart.length - 1];
};

export const createIsCartValidSelector = (settings: any) =>
  createSelector(
    (state: RootState) => state.cart.products, // Access products from the state
    (products: CartProduct[]): boolean => {
      if (!products?.length) return false;

      const MINIMUM_TOTAL_QUANTITY = getMininumOrderQuantity(settings); // Overall minimum for products
      const minimumQuantityGender = getSettingsNumberValue(settings, "minimum_quantity_gender", 25); // Per-sizingObject minimum

      return products.every((product) => {
        // Check if the total quantity of the product meets the overall minimum
        const totalQuantity = totalCartProductAmount(product);
        if (totalQuantity < MINIMUM_TOTAL_QUANTITY) {
          return false; // Product does not meet the total quantity requirement
        }

        // There should be no warning for order quantities for each gender
        const genderAmountWarnings = orderQuantityWarnings(product, minimumQuantityGender);
        return genderAmountWarnings.length === 0;
      });
    },
  );

export default cartSlice.reducer;
