import { FC } from "react";
import PrismicTranslation from "../PrismicTranslation";
import { CartProduct, Size, SizingObject } from "../../types";
import { Input } from "../ui/Input";

export const OrderQuantityGroup: FC<{
  cartItem: CartProduct;
  handleCartAmount: Function;
  groupName: string;
  groupNames: Array<string>;
  genderAmountWarnings: Array<{ gender: string; remaining: number }>;
}> = ({ cartItem, handleCartAmount, groupName, groupNames, genderAmountWarnings }) => {
  return (
    <div>
      {/* Warnings for gender minimum order quantity */}
      {groupNames.length > 1 &&
        genderAmountWarnings
          .filter((option) => option.gender === groupName)
          .map((option) => (
            <div className="text-red font-bold text-sm mb-2" key={option.gender}>
              <PrismicTranslation
                field="min_sizing_quantity"
                options={{
                  sizing_object: option.gender,
                  remaining: option.remaining,
                }}
                simple
              />
            </div>
          ))}

      {/* Order quantity */}
      {cartItem.amount
        ?.filter((sizingObject: SizingObject) => {
          return sizingObject.primary.gender === groupName;
        })
        .map((sizingObject: SizingObject) => (
          <div className="w-full" key={sizingObject.id}>
            <div className="font-bold">
              <PrismicTranslation field={sizingObject.primary?.translation_key} simple />
            </div>

            <div className="flex flex-wrap gap-2">
              {sizingObject.items.map(({ size, amount }: Size) => (
                <div key={size} className="flex w-[36px] flex-wrap">
                  <Input
                    label={size}
                    labelClassName="text-text"
                    alternateLabel
                    value={amount ?? ""}
                    placeholder="0"
                    type="number"
                    className="text-center !text-black !p-0.5"
                    onChange={(e) =>
                      handleCartAmount(
                        sizingObject.primary?.translation_key,
                        sizingObject.primary?.gender,
                        size,
                        e.currentTarget.valueAsNumber,
                      )
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};
