import { FC } from "react";
import {CartProduct} from "../../types";
import {getCartItemPrice} from "../../utils";

interface CartItemPriceProps {
  cartItem: CartProduct;
}

export const CartItemPrice: FC<CartItemPriceProps> = ({ cartItem }) => {
  return (
    <>
      {getCartItemPrice(cartItem).toFixed(2) + "€"}
    </>
  )

};
