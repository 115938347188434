import { defaultProductVariant } from "../components/Configurator/config";
import {
  CartProduct,
  ObjectWithId,
  ProductFicheData,
  ProductOption,
  ProductOptionConfig,
  ProjectEditables,
  ProjectLogo,
} from "../types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

function createBody(config: any, inCart: boolean | undefined) {
  return {
    id: config.id,
    project: config.projectId,
    productGroup: config.productGroup?.id,
    product: config.product?.id,
    mainFabric: config.fabric?.main?.id,
    contrastFabric: config.fabric?.contrast?.id,
    productOptions: config.productOptions?.map((option: ProductOption) => {
      const options = {
        id: option.id,
        colorOption: option.data.color_option,
      } as ProductOptionConfig;
      if (option.data?.logo_option) {
        options.logoOption = option.data.logo_option;
      }
      return options;
    }),
    inCart: inCart,
    productVariant: config.productVariant || defaultProductVariant,
    productModelPicture: config.productModelPicture,
    projectLogos:
      config.projectLogos.map((item: ProjectLogo) => ({
        // One project logo can only belong to one productconfig.
        // Clear the ProjectLogo id for new ProductConfigs.
        id: typeof config.id === 'undefined' ? '' : item.id,
        logoName: item.logoName,
        logoOption: item.logoOption,
        comment: item.comment,
      })) || [],
  };
}

// Define a service using a base URL and expected endpoints
export const nestApi = createApi({
  reducerPath: "nestApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_PATH,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    fetchProductGroups: builder.query({
      query: () => {
        return `/productgroups`;
      },
    }),
    fetchProducts: builder.query({
      query: () => {
        return `/products`;
      },
    }),
    fetchDistributors: builder.query({
      query: () => {
        return `/distributor`;
      },
    }),
    fetchProject: builder.query({
      query: (projectId: string) => {
        return `/project/${projectId}`;
      },
    }),
    fetchUserProjects: builder.query({
      query: (user: string) => {
        return `/project/user/${user}`;
      },
    }),
    fetchUserOrders: builder.query({
      query: (userId: string) => {
        return `/order/user2/${userId}`;
      },
    }),
    createTechPack: builder.mutation({
      query: (order: any) => {
        return {
          url: `/order/${order.id}/to-excel`,
          method: "POST",
          body: order,
        };
      },
    }),
    downloadProductFiche: builder.mutation({
      query: ({
        productFicheData,
        cb,
      }: {
        productFicheData: ProductFicheData;
        cb: Function | undefined;
      }) => {
        const {
          productConfig: { id },
          lang,
        } = productFicheData;
        return {
          url: `/productconfig/product-fiche/${id}/${lang}`,
          method: "GET",
          responseHandler: async (response) => {
            if (typeof cb === "function") {
              cb(response);
            }
          },
        };
      },
    }),
    createOrder: builder.mutation({
      query: (order: any) => {
        return {
          url: `/order/create`,
          method: "POST",
          body: order,
        };
      },
    }),
    duplicateProject: builder.mutation({
      query: (orderId: string) => {
        return {
          url: `/project/duplicate/${orderId}`,
          method: "POST",
        };
      },
    }),
    duplicateProductConfig: builder.mutation({
      query: (id: string) => {
        return {
          url: `/productconfig/duplicate/${id}`,
          method: "GET",
        };
      },
    }),
    updateProject: builder.mutation({
      query: (payload: { project: ProjectEditables }) => {
        return {
          url: `/project/${payload.project.id}`,
          method: "PATCH",
          body: payload.project,
        };
      },
    }),
    fetchProductConfig: builder.mutation({
      query: (payload: { id: string }) => {
        return {
          url: `/productconfig/${payload.id}`,
          method: "GET",
        };
      },
    }),
    removeProductConfig: builder.mutation({
      query: (payload: { cartItem: string; project: string }) => {
        return {
          url: `/productconfig/${payload.cartItem}`,
          method: "DELETE",
          body: {
            id: payload.cartItem,
            project: payload.project,
          },
        };
      },
    }),
    createOrUpdateProductConfig: builder.mutation({
      query: (payload: { config: any; inCart?: boolean }) => {
        const requestBody = createBody(payload.config, payload.inCart);
        return {
          url: `/productconfig/create`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: requestBody,
        };
      },
    }),
    // Update ProductConfig
    // Changes to "inCart" and "Project" are ignored by the backend
    updateProductConfig: builder.mutation({
      query: (payload: { config: any }) => {
        return {
          url: `/productconfig/${payload.config.id}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: createBody(payload.config, undefined),
        };
      },
    }),
    // Add to cart
    // Backend will duplicate the ProductConfig and set inCart and return the id of the actual ProductConfig in the cart.
    addProductConfigToCart: builder.mutation({
      query: (payload: ObjectWithId) => {
        return {
          url: `/productconfig/add-to-cart/${payload.id}`,
          method: "GET",
        };
      },
    }),
    // Update ProductConfig product model render
    updateProductConfigProductImage: builder.mutation({
      query: (payload: {
        config: { id: string; productModelPicture: string };
      }) => {
        return {
          url: "/productconfig/image",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload.config),
        };
      },
    }),
    uploadProjectImage: builder.mutation({
      query: (file: File) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: `/image`,
          method: "POST",
          body: formData,
        };
      },
    }),
    createProject: builder.mutation({
      query: (project: any) => {
        return {
          url: `/project/create`,
          method: "POST",
          body: project,
        };
      },
    }),
    cartItemPrice: builder.mutation({
      query: (cartProduct: CartProduct) => {
        return {
          url: `/order/cart-item/price`,
          method: "POST",
          body: cartProduct,
        };
      },
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useCreateTechPackMutation,
  useDuplicateProjectMutation,
  useDuplicateProductConfigMutation,
  useFetchProductGroupsQuery,
  useFetchProductsQuery,
  useFetchDistributorsQuery,
  useLazyFetchProjectQuery,
  useFetchUserOrdersQuery,
  useFetchUserProjectsQuery,
  useFetchProductConfigMutation,
  useRemoveProductConfigMutation,
  useUpdateProjectMutation,
  useUploadProjectImageMutation,
  useCreateOrUpdateProductConfigMutation,
  useUpdateProductConfigMutation,
  useAddProductConfigToCartMutation,
  useUpdateProductConfigProductImageMutation,
  useDownloadProductFicheMutation,
  useCreateProjectMutation,
  useCartItemPriceMutation,
} = nestApi;
